@import 'styles/includes.scss';

.HelpBlock {
    margin: 39px 0 0;

    @include media(m) {
        margin: 66px 0 92px;
    }

    &__Container {
        @extend %container-desktop;
    }

    &__Title {
        @extend %h2;
        margin: 0 auto;
        text-align: center;
        max-width: 896px;
        padding: 0 34px;
        margin-bottom: 14px;
    }

    &__Text {
        @extend %body-copy;
        display: none;

        @include media(m) {
            display: block;
            margin: 0 auto;
            text-align: center;
            max-width: 896px;
        }
    }

    &__Blocks {
        display: flex;
        flex-flow: column;
        margin-top: 24px;

        @include media(m) {
            margin-top: 57px;
            flex-flow: row;
            justify-content: space-between;
        }
    }

    &__Block {
        width: 100%;
        background: #fff;
        padding: 37px;
        display: flex;
        border-top: solid 1px $colorBlack200;
        position: relative;

        &:last-child {
            border-bottom: solid 1px $colorBlack200;
        }

        @include media(m) {
            width: 100%;
            flex-flow: column;
            justify-content: space-between;
            border: none;
            margin: 0 12px;

            &:first-child {
                margin-left: 0;
            }

            &:last-child {
                margin-right: 0;
            }
        }

        &:after {
            content: '';
            display: inline-block;
            width: 11px;
            height: 18px;
            background-image: url('#{$assetPath}svg/arrow--right-blue.svg');
            background-size: contain;
            background-repeat: no-repeat;
            vertical-align: middle;
            position: absolute;
            right: 34px;
            top: 0;
            bottom: 0;
            margin-top: auto;
            margin-bottom: auto;

            @include media(m) {
                display: none;
            }
        }

        &--Link {
            color: $colorBlack;
            text-decoration: none;
            padding: 21px 24px 21px 24px;
        }
    }

    &__Button {
        margin: 0 auto;
        padding-top: 30px;
    }

    &__BlockInner {
        display: flex;

        @include media(m) {
            display: block;
            margin: 0 auto;
            max-width: 205px;
            text-align: center;
        }
    }

    &__Icon {
        width: 60px;
        height: 60px;
        background-color: $colorYellowSecondary;
        margin: 0 auto;
        border-radius: 50%;
        background-position: center;
        background-repeat: no-repeat;
        background-size: 35px;

        @include media(m) {
            width: 152px;
            height: 152px;
            margin-bottom: 34px;
            background-size: initial;
        }

        &--questions-and-answers {
            background-image: url('#{$assetPath}svg/questions-and-answers.svg');
        }

        &--customer-service {
            background-image: url('#{$assetPath}svg/customer-service.svg');
        }

        &--cogwheels {
            background-image: url('#{$assetPath}svg/cogwheels.svg');
            background-size: 40px;

            @include media(m) {
                background-size: initial;
            }
        }
    }

    &__BlockTitle {
        @extend %h3;
        align-self: center;
        margin: 0 22px;

        @include media(m) {
            margin-left: 0;
            margin: 0 auto;
        }
    }

    &__BlockSubText {
        @extend %body-copy-small;
        display: none;

        @include media(m) {
            display: block;
            padding-top: 16px;
        }
    }
}
